import { createRouter, createWebHistory } from "vue-router";

import Auth from "@/pages/auth";
import Main from "@/pages/main";
import store from '@/store';

const routes = [
  {
    path: "/",
    name: "main",
    component: Main,
    meta: {auth: true}
  },
  {
    path: "/login",
    name: "auth",
    component: Auth
  },
  {
    path: "/ya-login",
    name: "ya-auth",
    // component: Auth
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.name === 'ya-auth') {
    const access_token = /access_token=([^&]+)/.exec(document.location.hash)[1];
    localStorage.setItem('ya-token', access_token);
    await store.dispatch('fetchYaAuth');
    await store.dispatch('fetchUser');
    next({name: "main"});
  }
  if(!token && to.name !== 'auth') {
    next({
      name: "auth"
    });
  }
  if (!store.state.user.user && token) {
    await store.dispatch('fetchUser');
  }
  if (store.state.user.user) {
    if(to.name === 'auth') {
      next({name: "main"});
    }
    if (to.meta.auth) {
      next();
    }
  } else {
    if (to.meta.auth) {
    next({
      name: "auth"
    });
    } else {
      next();
    }
  }
});

export default router;
