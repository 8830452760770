<template>
<div class="content" v-if="getUser">
    <div class="mode">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.29785" y="3.79791" width="13.7778" height="13.7778" rx="0.444444" fill="#959595"/>
            <rect x="0.44458" y="10.6868" width="13.7778" height="13.7778" rx="0.444444" transform="rotate(-45 0.44458 10.6868)" fill="#959595"/>
            <circle cx="10.2223" cy="10.7222" r="6.22222" fill="#F1F1F1"/>
        </svg>
        <label class="switch">
            <input type="checkbox" v-model="checked">
            <span class="slider round" v-on:click="setTheme"></span>
        </label>
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="moon">
            <path d="M18.842 13.5272C15.4544 15.0608 11.4122 14.3322 8.78915 11.7094C6.16606 9.08659 5.43742 5.04488 6.97592 1.66246C7.06192 1.46898 7.02131 1.24205 6.86841 1.09634C6.72269 0.943459 6.49335 0.902851 6.30223 0.988844C5.19374 1.49047 4.20471 2.17365 3.37095 3.01447C1.48366 4.89917 0.444458 7.40732 0.444458 10.0755C0.444458 12.7389 1.48366 15.2447 3.37095 17.1342C5.25586 19.0165 7.76189 20.0556 10.4304 20.0556C13.0941 20.0556 15.6001 19.0165 17.4898 17.1342C18.3259 16.2933 19.0092 15.3068 19.5109 14.1961C19.5969 14.0026 19.5563 13.7804 19.4105 13.6299C19.2576 13.4818 19.0355 13.4364 18.842 13.5272Z" />
        </svg>
    </div>
    <div class="logout" v-on:click="logout">
        <div class="logout-btn">Выйти</div>
    </div>
    <div class="header">
        <div class="names">
            <div class="name">{{ getUser.first_name }} {{ getUser.last_name }}</div>
            <div class="current-status" :class="currentStatus.bg">
                <span class="rect" :class="currentStatus.color"></span>{{ currentStatus.status }}
            </div>
        </div>
    </div>
    <div class="wrapper">
        <div class="container">
            <div class="centered-box">
                <div class="status-flex"><span class="status">Пришёл на работу</span>
                    <div
                    v-if="!(getUser.current_flow && getUser.current_flow.timestamps.day_start)"
                    v-on:click="dayStart"
                    class="btn-set-status"
                    id="day-start">Отметиться
                    </div>
                    
                    <div
                    v-else
                    class="btn-set-status press"
                    id="day-start"
                    v-on:click="deletetime(getUser.current_flow.timestamps.day_start.id)"
                    >
                        Удалить
                    </div>

                    <div
                    v-if="(getUser.current_flow && getUser.current_flow.timestamps.day_start)"
                    class="flagged-time"
                    >
                        {{ [new Date(getUser.current_flow.timestamps.day_start.time_set).getHours(), new Date(getUser.current_flow.timestamps.day_start.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                    </div>
                </div>
                <div class="status-flex"><span class="status">Начало обеда</span>
                    <div
                    v-if="!(getUser.current_flow && getUser.current_flow.timestamps.timeout_start)"
                    class="btn-set-status"
                    >
                        <div class="hint-1 text-color" v-if="showFirstHint">
                            Сначала нужно прийти на работу, а потом можно и пообедать ;)
                            <div class="close" v-on:click="closeHint(1)"></div>
                        </div>
                        <div v-on:click="timeoutStart">Отметиться</div>
                    </div>

                    <div
                    v-else
                    class="btn-set-status press"
                    v-on:click="deletetime(getUser.current_flow.timestamps.timeout_start.id)"
                    >
                        Удалить
                    </div>

                    <div
                    v-if="getUser.current_flow && getUser.current_flow.timestamps.timeout_start"
                    class="flagged-time"
                    >
                        {{ [new Date(getUser.current_flow.timestamps.timeout_start.time_set).getHours(), new Date(getUser.current_flow.timestamps.timeout_start.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                    </div>
                </div>
                <div class="status-flex"><span class="status">Конец обеда</span>
                    <div
                    v-if="!(getUser.current_flow && getUser.current_flow.timestamps.timeout_end)"
                    class="btn-set-status"
                    >
                        <div class="hint-3 text-color" v-if="showThirdHint">
                            Сначала нужно уйти на обед,<br>а потом можно и продолжить работать ;)
                            <div class="close" v-on:click="closeHint(3)"></div>
                        </div>
                        <div v-on:click="timeoutEnd">Отметиться</div>
                    </div>

                    <div
                    v-else
                    class="btn-set-status press"
                    v-on:click="deletetime(getUser.current_flow.timestamps.timeout_end.id)"
                    >
                        Удалить
                    </div>

                    <div
                    v-if="getUser.current_flow && getUser.current_flow.timestamps.timeout_end"
                    class="flagged-time"
                    >
                        {{ [new Date(getUser.current_flow.timestamps.timeout_end.time_set).getHours(), new Date(getUser.current_flow.timestamps.timeout_end.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                    </div>
                </div>
                <div class="status-flex"><span class="status">Ушёл домой</span>
                    <div
                    v-if="!(getUser.current_flow && getUser.current_flow.timestamps.day_end)"
                    class="btn-set-status"
                    >
                        <div class="hint-2 text-color" v-if="showSecondHint">
                            Чтобы уйти с работы,<br>сначала надо на неё<br>прийти
                            <div class="close" v-on:click="closeHint(2)"></div>
                        </div>
                        <div v-on:click="dayEnd">Отметиться</div>
                    </div>

                    <div
                    v-else
                    class="btn-set-status press"
                    v-on:click="deletetime(getUser.current_flow.timestamps.day_end.id)"
                    >
                        Удалить
                    </div>

                    <div
                    v-if="getUser.current_flow && getUser.current_flow.timestamps.day_end"
                    class="flagged-time"
                    >
                        {{ [new Date(getUser.current_flow.timestamps.day_end.time_set).getHours(), new Date(getUser.current_flow.timestamps.day_end.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper-users" v-if="getUser.is_staff">
        <div class="container">
            <div class="block-users" style="color: white;">
                <div class="start-day text-color">
                    Отбиваются от правок
                    <ul class="ul-style" v-if="getUsersStatus">
                        <li
                        v-for="person in getUsersStatus.working" 
                        :key="person.first_name" 
                        class="li-style"
                        v-on:click="openModal(person)"
                        >
                            {{ person.first_name }} {{ person.last_name }}
                        </li>
                    </ul>
                </div>
                <div class="timeout-day text-color">
                    Кушают фуагра
                    <ul class="ul-style" v-if="getUsersStatus">
                        <li 
                        v-for="person in getUsersStatus.lunch" 
                        :key="person.first_name" 
                        class="li-style"
                        v-on:click="openModal(person)"
                        >
                            {{ person.first_name }} {{ person.last_name }}
                        </li>
                    </ul>
                </div>
                <div class="timeout-end text-color">
                    Ушли в закат
                    <ul class="ul-style" v-if="getUsersStatus">
                        <li 
                        v-for="person in getUsersStatus.end_work" 
                        :key="person.first_name" 
                        class="li-style"
                        v-on:click="openModal(person)"
                        >
                            {{ person.first_name }} {{ person.last_name }}
                        </li>
                    </ul>
                </div>
                <div class="day-end text-color">
                    Профилонили
                    <ul class="ul-style" v-if="getUsersStatus">
                        <li 
                        v-for="person in getUsersStatus.not_working" 
                        :key="person.first_name" 
                        class="li-style"
                        v-on:click="openModal(person)"
                        >
                            {{ person.first_name }} {{ person.last_name }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <a href="mailto:uralov@dobroagency.ru">
        <div class="admin-help">
            Написать админу
        </div>
    </a>
    <div v-if="showModal" class="modal-shadow" @click.self="closeModal">
        <div class="modal">
            <div class="modal-close" @click="closeModal">&#10005;</div>
            <div class="modal-title">{{ watchUser.first_name }} {{ watchUser.last_name }}</div>
            <div>
                <div class="modal-content">
                    <VDatePicker v-model="date" :is-dark="!checked"/>
                    <ul class="ul-status">
                        <li class="li-status">
                            <span>Начал работать:</span>
                            <span v-if="getUserFlow && getUserFlow.timestamps && getUserFlow.timestamps.day_start"
                            >
                                {{ [new Date(getUserFlow.timestamps.day_start.time_set).getHours(), new Date(getUserFlow.timestamps.day_start.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                            </span>
                        </li>
                        <li class="li-status">
                            <span>Ушел на обед:</span>
                            <span v-if="getUserFlow && getUserFlow.timestamps && getUserFlow.timestamps.timeout_start">
                                {{ [new Date(getUserFlow.timestamps.timeout_start.time_set).getHours(), new Date(getUserFlow.timestamps.timeout_start.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                            </span>
                        </li>
                        <li class="li-status">
                            <span>Вернулся с обеда:</span>
                            <span v-if="getUserFlow && getUserFlow.timestamps && getUserFlow.timestamps.timeout_end">
                                {{ [new Date(getUserFlow.timestamps.timeout_end.time_set).getHours(), new Date(getUserFlow.timestamps.timeout_end.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                            </span>
                        </li>
                        <li class="li-status">
                            <span>Ушел домой:</span>
                            <span v-if="getUserFlow && getUserFlow.timestamps && getUserFlow.timestamps.day_end">
                                {{ [new Date(getUserFlow.timestamps.day_end.time_set).getHours(), new Date(getUserFlow.timestamps.day_end.time_set).getMinutes()].map(function (x) {return x < 10 ? "0" + x : x}).join(":") }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div name="footer">
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <ModalWindow v-if="showModal"/> -->
</template>

<script>
import {mapGetters, mapActions} from "vuex"

export default {
    name: 'MainPage',
    data() {
        return {
            showFirstHint: false,
            showSecondHint: false,
            showThirdHint: false,
            checked: localStorage.getItem('theme') == 'dark' ? false : true,
            showModal: false,
            date: new Date(),
            watchUser: null,
        }
    },
    watch: {
        date(value) {
            this.date = value;
            const current_date = [this.date.getFullYear(), this.date.getUTCMonth()+1, this.date.getDate()].map(function (x) {return x < 10 ? "0" + x : x}).join("-")
            this.fetchUserFlow({date: current_date, user_id: this.watchUser.id});
        }
    },
    methods: {
        ...mapActions(['setTimestamp', 'deleteTimestamp', 'fetchUser', 'fetchUsersStatus', 'fetchLogout', 'fetchUserFlow']),
        async dayStart() {
            const data = {
                time_set: new Date().toJSON(),
                stamp_type: "DS",
            };
            await this.setTimestamp(data);
            const res = await this.fetchUser();
            if (res == 401) {
                this.router.push({name: "auth"});
            }
            await this.fetchUsersStatus();
            this.closeHint();
        },
        async timeoutStart() {
            if (this.getUser.current_flow && this.getUser.current_flow.timestamps.day_start) {
                const data = {
                time_set: new Date(),
                stamp_type: "LS",
                flow: this.getUser.current_flow.id,
                };
                await this.setTimestamp(data);
                await this.fetchUser();
                await this.fetchUsersStatus();
            } else {
                this.closeHint();
                this.showFirstHint = true;
            }
        },
        async timeoutEnd() {
            if (this.getUser.current_flow && this.getUser.current_flow.timestamps.timeout_start) {
                const data = {
                    time_set: new Date(),
                    stamp_type: "LE",
                    flow: this.getUser.current_flow.id,
                };
                await this.setTimestamp(data);
                await this.fetchUser();
                await this.fetchUsersStatus();
            } else {
                this.closeHint();
                this.showThirdHint = true;
            }
        },
        async dayEnd() {
            if (this.getUser.current_flow && ((this.getUser.current_flow.timestamps.day_start && !this.getUser.current_flow.timestamps.timeout_start) || (this.getUser.current_flow.timestamps.day_start && this.getUser.current_flow.timestamps.timeout_start && this.getUser.current_flow.timestamps.timeout_end))) {
                const data = {
                    time_set: new Date(),
                    stamp_type: "DE",
                    flow: this.getUser.current_flow.id,
                };
                await this.setTimestamp(data);
                await this.fetchUser();
                await this.fetchUsersStatus();
            } else {
                this.closeHint();
                this.showSecondHint = true;
            }
        },
        async deletetime(id) {
            await this.deleteTimestamp({id: id});
            await this.fetchUser();
            await this.fetchUsersStatus();
        },
        async logout() {
            await this.fetchLogout();
            this.$router.push({name: 'auth'});
        },
        async openModal(user) {
            document.querySelector('body').style.overflowY = 'hidden'
            document.querySelector('html').style.overflowY = 'hidden'
            this.watchUser = user;
            const current_date = [this.date.getFullYear(), this.date.getUTCMonth()+1, this.date.getDate()].map(function (x) {return x < 10 ? "0" + x : x}).join("-")
            await this.fetchUserFlow({date: current_date, user_id: user.id});
            this.showModal = true;

        },
        setTheme() {
            const elem = document.getElementById("theme");
            if (localStorage.getItem("theme") == "dark") {
                elem.classList.remove('dark');
                elem.classList.add('light');
                localStorage.setItem("theme", "light")
            } else {
                elem.classList.remove('light');
                elem.classList.add('dark');
                localStorage.setItem("theme", "dark")
            }
        },
        toggleTheme() {
            const activeTheme = localStorage.getItem("user-theme");
            if (activeTheme === "light") {
                this.setTheme("dark");
            } else {
                this.setTheme("light");
            }
        },
        closeHint(from){
            if (from == 1) {
                this.showFirstHint = false;
            } else if (from == 2) {
                this.showSecondHint = false;
            } else if (from == 3) {
                this.showThirdHint = false;
            } else if (!from) {
                this.showFirstHint = false;
                this.showSecondHint = false;
                this.showThirdHint = false;
            }
        },
        closeModal() {
            this.showModal = false;
            document.querySelector('body').style.overflowY = ''
            document.querySelector('html').style.overflowY = ''
        },
    },
    async created() {
        await this.fetchUsersStatus();
    },
    computed: {
        ...mapGetters(['getUser', 'getUsersStatus', 'getUserFlow']),
        currentStatus() {
            if (this.getUser.current_flow) {
                if (this.getUser.current_flow.timestamps.day_end) {
                    return {status: "Не работает", color: "red", bg: "bg-red"}
                } else if (this.getUser.current_flow.timestamps.timeout_end) {
                    return {status: "Работает", color: "green", bg: "bg-green"}
                } else if (this.getUser.current_flow.timestamps.timeout_start) {
                    return {status: "На обеде", color: "yellow", bg: "bg-yellow"}
                } else if (this.getUser.current_flow.timestamps.day_start) {
                    return {status: "Работает", color: "green", bg: "bg-green"}
                }
            }
            return {status: "Не работает", color: "red", bg: "bg-red"}
        },
    },
}
</script>

<style scoped>
.content {
    padding: 0 40px;
}
.status-flex {
    display: grid;
    grid-template: "status button time";
    grid-template-columns: 200px 170px 170px;
    width: 450px;
    font-family: "Pragmatica Light", sans-serif;
}

.header {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    top: 80px;
    position: relative;
}

.header .names {
    display: flex;
    align-items: center;
    justify-content: space-around;;
    align-self: center;
    font-size: 55px;
    font-weight: 600;
}

.name {
    color: var(--theme-color);
    font-family: 'Pragmatica Extended', sans-serif;
    font-size: 50px;
}

.rect {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
}

.red {
    background-color: #BE4343;
}

.bg-red {
    background-color: var(--status-red-bg);
    color: #BE4343;
}

.yellow {
    background-color: #FF974A;
}

.bg-yellow {
    background-color: var(--status-yellow-bg);
    color: #FF974A;
}

.green {
    background-color: #3DD598;
}

.bg-green {
    background-color: var(--status-green-bg);
    color: #3DD598;
}

.current-status {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    padding: 5px 16px;
    margin-left: 16px;
    border-radius: 8px;
    font-family: "Pragmatica Light", sans-serif;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
}

.wrapper-users {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.centered-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}



input::-webkit-input-placeholder {
    position: relative;
    color: var(--theme-color);
}

.centered-box {
    height: 266px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.btn {
    display: block;
    width: 100;
    height: 52px;
    font-size: 20px;
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    border-radius: 100px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-left: 10px;
    padding: 0 50px;
    cursor: pointer;
}

.btn-set-status {
    position: relative;
    padding: 7px;
    width: 140px;
    font-size: 16px;
    background-color: var(--btn-color);
    border: none;
    border-radius: 100px;
    cursor: pointer;
    grid: "button";
    color: var(--btn-text);
    text-align: center;
}

button {
    background-color: red;
}

.press {
    background-color: var(--btn-press);
    border: 1px solid var(--theme-color);
    color: var(--btn-color);
}

.status {
    color: var(--theme-color);
    font-size: 20px;
    font-weight: 200;
    grid: "status";
    display: flex;
    align-items: center;
}

.admin-help {
    position: fixed;
    right: 40px;
    bottom: 40px;
    border: 1px solid var(--theme-color);
    border-radius: 100px;
    padding:8px 25px;
    cursor: pointer;
    font-family: "Pragmatica Light", sans-serif;
    text-decoration: none;
    color: var(--theme-color);
}


.mode {
    position: fixed;
    left: 40px;
    top: 20px;
    display: flex;
    align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 73px;
  height: 33px;
  margin: 0 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  background-color: var(--btn-mode-bg);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  right: 4px;
  bottom: 4px;
  background-color:var(--btn-mode-toggle);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(-39px);
  -ms-transform: translateX(-39px);
  transform: translateX(-39px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.flagged-time {
    color: var(--btn-color);
    grid: "time";
    display: flex;
    align-items: center;
}

.moon {
    fill: var(--moon);
}

.block-users {
    display: flex;
    justify-content: space-between;
    width: 950px;
    color: var(--theme-color);
    font-family: 'Pragmatica Extended', sans-serif;
    font-size: 20px;
}

.ul-style {
    margin-top: 20px;
    padding: 0;
}

.li-style {
    list-style-type: none;
    font-family: "Pragmatica Light", sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.text-color {
    color: var(--theme-color);
}

.hint-1, .hint-2, .hint-3 {
    position: absolute;
    width: 240px;
    background-color: var(--hint-color);
    right: -275px;
    border-radius: 8px;
    text-align: start;
    font-family: "Pragmatica Light", sans-serif;
    padding: 8px;
    z-index: 1;
    top: -20px;
    cursor: default;
}

.hint-1::before, .hint-2::before, .hint-3::before {
    content: '';
    position: absolute;
    border: 6px solid transparent; 
    border-right: 15px solid var(--hint-color);
    left: -20px;
    top: 30px;
}

.d-none {
    display: none;
}

.close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 10px;
}

.logout {
    position: fixed;
    right: 40px;
    top: 20px;
    background-color: var(--theme-color);
    color: var(--btn-text);
    padding: 11px 30px;
    border-radius: 100px;
    font-size: 16px;
    font-family: 'Pragmatica Extended', sans-serif;
    cursor: pointer;
}

.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.39);
}
 
.modal {
    background: var(--theme-color);
    border-radius: 8px;
    padding: 15px;
    min-width: 620px;
    max-width: 880px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
 
.modal-close {
    color: var(--btn-mode-toggle);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 7px;
    right: 7px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
 
.modal-title {
    color: var(--btn-text);
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Pragmatica Extended', sans-serif;
}
 
.modal-content {
    display: flex;
}
 
.modal-footer__button {
    background-color: #0971c7;
    color: #fff;
    border: none;
    text-align: center;
    padding: 8px;
    font-size: 17px;
    font-weight: 500;
    border-radius: 8px;
    min-width: 150px;
}

.ul-status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--btn-text);
}

.li-status {
    list-style-type: none;
    font-family: "Pragmatica Light", sans-serif;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    width: 250px;
}

.close::before, .close::after {content: ""; position: absolute; width: 20px; height: 2px; background: gray;  right: 100%;}
.close::before {transform: rotate(45deg);}
.close::after {transform: rotate(-45deg);}
</style>

<style>
.vc-arrow, .vc-title {
    background-color: transparent;
}
</style>