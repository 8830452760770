import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from './store'
import './assets/css/fonts.css'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const app = createApp(App)
app.use(store);
app.use(router);
app.use(VCalendar, {})
app.mount('#app');
