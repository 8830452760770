export default ({
    state: {
        flow: {},
    },
    getters: {
        getFlow(state) {
            return state.flow
        }
    },
    mutations: {
        updateFlow(state, flow) {
            state.flow = flow;
        },
    },
    actions: {
        async fetchFlow(ctx) {
            try {
                const res = await fetch(`http://127.0.0.1:8000/api/flow/`, {
                    credentials: "include",
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                });
                if (!res.ok) {
                    throw new Error(`Ошибка: ${res.status}`);
                }
                const flow = await res.json();
                ctx.commit('updateFlow', flow);
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error.message);
            }
        }
    },
})
