<template>
    <div class="header">
        <div class="logo">
            <div class="logo-dobro">
                DOBRO
            </div>
            <div class="logo-time">
                Time
            </div>
        </div>
    </div>
    <div class="error">
        Не удалось войти с этими данными.
    </div>
    <div class="wrapper">
        <div class="container">
            <div class="centered-box">
                <input type="text" placeholder="Логин" class="login" v-model="username">
                <input type="password" placeholder="Пароль" class="password" v-model="password">
                <button class="btn" v-on:click="loginMethod">Войти</button>
                <div class="or">Или</div>
                <a href="https://oauth.yandex.ru/authorize?response_type=token&client_id=e0b1b0b638d34d4f960178eb4a020f56" class="yanex">
                    <button class="btn-ya">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px;">
                            <path d="M1.7002 10.5C1.7002 5.89752 5.4302 2.16669 10.0335 2.16669C14.6352 2.16669 18.3669 5.89752 18.3669 10.5C18.3669 15.1025 14.6352 18.8334 10.0335 18.8334C5.4302 18.8334 1.7002 15.1025 1.7002 10.5Z" fill="#FC3F1D"/>
                            <path d="M11.1 6.88832H10.33C8.91837 6.88832 8.17587 7.60332 8.17587 8.65749C8.17587 9.84915 8.6892 10.4075 9.74337 11.1233L10.6142 11.71L8.1117 15.4492H6.2417L8.48753 12.1042C7.19587 11.1783 6.47087 10.2792 6.47087 8.75832C6.47087 6.85165 7.80003 5.54999 10.3209 5.54999H12.8234V15.44H11.1V6.88832Z" fill="white"/>
                        </svg>
                        Войти с Яндекс ID
                    </button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"

export default {
    name: 'AuthPage',
    data: () => ({
        username: '',
        password: ''
    }),
    computed: {
        ...mapGetters(['getAuth', 'getUser']),
    },
    methods: {
        ...mapActions(['fetchAuth', 'fetchUser']),
        async loginMethod() {
            const res = await this.fetchAuth({username: this.username, password: this.password});
            console.log(this.getAuth);
            if (this.getAuth) {
                await this.fetchUser();
                this.$router.push('/');
            } else if (res.non_field_errors) {
                this.showError();
            }
        },
        showError() {
            const elem = document.getElementsByClassName("error")[0];
            elem.classList.add('visible');
            setTimeout(() => {elem.classList.remove('visible')}, 3000)
        }
    }
}
</script>

<style>
.header {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    top: 100px;
    position: relative;
}

.header .logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 69px;
    width: 357px;
    font-size: 55px;
    font-weight: 800;
    font-family: 'Pragmatica Extended', sans-serif;
    line-height: 69px;
}

.logo-dobro {
    display: flex;
    align-items: center;
    background-color: var(--theme-color);
    color: var(--btn-text);
    padding: 0 5px;
    height: 45px;
    margin-right: 10px;
}

.logo-time {
    color: var(--btn-color);
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 230px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.centered-box {
    height: 296px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.centered-box input {
    display: block;
    width: 327px;
    height: 52px;
    color: var(--btn-color);
    font-size: 20px;
    background-color: var(--body-bg);
    border: 1px solid var(--theme-color);
    border-radius: 100px;
    outline:none;
    padding-left: 24px;
}


input::-webkit-input-placeholder {
    position: relative;
    color: var(--btn-color);
    font-family: 'Pragmatica Extended', sans-serif;
}

.or {
    font-family: 'Pragmatica Extended', sans-serif;
    color: var(--btn-color);
}

.btn {
    display: block;
    width: 357px;
    height: 52px;
    font-size: 20px;
    background-color: var(--theme-color);
    color: var(--btn-text);
    border-radius: 100px;
    padding-left: 10px;
    padding: 0 50px;
    cursor: pointer;
    font-family: 'Pragmatica Extended', sans-serif;
}

.yandex {
    text-decoration: none;
}

.btn-ya {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 357px;
    height: 52px;
    font-size: 16px;
    background-color: var(--body-bg);
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    border-radius: 100px;
    padding: 0 50px;
    cursor: pointer;
    font-family: 'Pragmatica Extended', sans-serif;
    text-decoration: none;
}

.error {
    position: absolute;
    background-color: #BE4343;
    color: black;
    padding: 10px;
    border-radius: 4px;
    right: 10px;
    text-align: center;
    transition: 1s;
    opacity: 0;
}

.visible {
    opacity: 1;
    transition: 2s;
}

.yanex {
    text-decoration: none;
}
</style>