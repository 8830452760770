export default ({
    state: {
        auth: null
    },
    getters: {
        getAuth(state) {
            return state.auth
        }
    },
    mutations: {
        updateAuth(state, auth) {
            state.auth = auth;
        },
    },
    actions: {
        async fetchAuth(ctx, data) {
            const res = await fetch(`${process.env.VUE_APP_API_URL}api/auth/`, {
                credentials: "include",
                method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            if (!res.ok) {
                const error = await res.json()
                return error
            } else {
                const auth = await res.json();
                ctx.commit('updateAuth', auth);
                localStorage.setItem('token', auth.token);
            }
        },
        async fetchYaAuth(ctx) {
            const data = {
                token: localStorage.getItem('ya-token')
            }
            const res = await fetch(`${process.env.VUE_APP_API_URL}api/ya-auth/`, {
                credentials: "include",
                method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            if (!res.ok) {
                const error = await res.json()
                return error
            } else {
                const auth = await res.json();
                ctx.commit('updateAuth', auth);
                localStorage.setItem('token', auth.token);
            }
        },
    },
})
