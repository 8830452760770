import { createStore } from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import flow from './modules/flow'

export default createStore({
    modules: {
        auth,
        user,
        flow,
    }
})
