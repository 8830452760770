<template>
  <div :class="this.theme" id="theme">
    <router-view> </router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      theme: localStorage.getItem("theme") || "dark"
    }
  },
  mounted() {
    const theme = localStorage.getItem("theme") || "dark"
    localStorage.setItem("theme", theme);
  },
  methods: {
    // setTheme() {
    //   const elem = document.getElementById("theme");
    //   if (this.theme == 'dark') {
    //     elem.classList.remove('dark');
    //     elem.classList.add('light');
    //   } else {
    //     elem.classList.remove('light');
    //     elem.classList.add('dark');
    //   }
    // }
  }
}
</script>

<style>
@charset "utf-8";

body {
    margin: 0;
    padding: 0;
}

.dark {
  background-color: #000;
  min-height: 100vh;
  height: 100%;
  --body-bg: #000;
  --theme-color: #fff;
  --btn-color: #fff;
  --btn-border: #fff;
  --btn-text: #000;
  --btn-press: #000;
  --status-red-bg: #130707;
  --status-yellow-bg: #190F07;
  --status-green-bg: #06150F;
  
  --btn-mode-bg: #fff;
  --btn-mode-toggle: #686868;
  --moon: #fff;

  --hint-color: #292932
}

.light {
  background-color: #fff;
  height: 100vh;
 --body-bg: #fff;
 --theme-color: #000;
 --btn-color: #000;
 --btn-border: #000;
 --btn-text: #fff;
 --btn-press: #fff;
 --status-red-bg: #F9EDED;
 --status-yellow-bg: #FFF5ED;
 --status-green-bg: #ECFBF5;

 --btn-mode-bg: #D2D2D2;
 --btn-mode-toggle: #fff;
 --moon: #EEEDED;

 --hint-color: #EAEAEB
}
</style>
