export default ({
    state: {
        user: null,
        users_status: null,
        user_flow: null,
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getUsersStatus(state) {
            return state.users_status
        },
        getUserFlow(state) {
            return state.user_flow
        },
    },
    mutations: {
        updateUser(state, user) {
            state.user = user;
        },
        updateUsersStatus(state, users_status) {
            state.users_status = users_status;
        },
        updateUserFlow(state, user_flow) {
            state.user_flow = user_flow;
        },
    },
    actions: {
        async fetchUser(ctx) {
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}api/user/me/`, {
                    credentials: "include",
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                });
                if (!res.ok) {
                    return res.status
                }
                const user = await res.json();
                ctx.commit('updateUser', user);
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error.message);
            }
        },
        async fetchUsersStatus(ctx) {
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}api/user/users_status/`, {
                    credentials: "include",
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                });
                if (!res.ok) {
                    return res.status
                }
                const users_status = await res.json();
                ctx.commit('updateUsersStatus', users_status);
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error.message);
            }
        },
        async setTimestamp(ctx, data) {
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}api/timestamp/`, {
                    credentials: "include",
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(data)
                });
                if (!res.ok) {
                    throw new Error(`Ошибка: ${res.status}`);
                }
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error.message);
            }
        },
        async deleteTimestamp(ctx, data) {
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}api/timestamp/${data.id}`, {
                    credentials: "include",
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                });
                if (!res.ok) {
                    throw new Error(`Ошибка: ${res.status}`);
                }
                return res.status
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error.message);
            }
        },
        async fetchLogout(ctx) {
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}api/logout/`, {
                    credentials: "include",
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                });
                if (!res.ok) {
                    throw new Error(`Ошибка: ${res.status}`);
                }
                localStorage.removeItem('token');
                ctx.commit('updateUser', null)
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error.message);
            }
        },
        async fetchUserFlow(ctx, data) {
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}api/flow/?date=${data.date}&user=${data.user_id}`, {
                    credentials: "include",
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                });
                if (!res.ok) {
                    throw new Error(`Ошибка: ${res.status}`);
                }
                const user_flow = await res.json();
                ctx.commit('updateUserFlow', user_flow[0]);
            } catch (error) {
                console.error('Произошла ошибка при выполнении запроса:', error.message);
            }
        }
    },
})
